/* .overlay{
  position: relative;
} */
.overlay {
  /* Overlay background */
  z-index: 100;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 500ms;
  background: rgba(0, 0, 0, .5);
  border-radius: 12px;
  /* Text */
  color: #f5f5f5;
  /* Position */
  display: flex;
  flex-direction: column;
}

.overlay:hover {
  opacity: 1;
  transition: opacity 500ms;
}

.title {
  display: flex;
  justify-content: center;
  margin: 10px;
  padding-top: 10px;
}

.username {
  position: relative;
  padding: 20px;
}

.content {
  display: flex;
  margin: 10 20px;
  padding: 20px;
}
.interact{
  position: relative;
  max-width: 30px;
  max-height: 30px;
  left: 80%;
  bottom: 0;
}