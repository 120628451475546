@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,100;1,300;1,400&display=swap');
.app {
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.flare {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 22px;
}
.divider {
  margin: 2% 0;
}
.posts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.post {
  position: relative;
  width: 24%;
  margin: 2%;
}
.post-image{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}