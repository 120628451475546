@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,100;1,300;1,400&display=swap);

.app {
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.flare {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 22px;
}
.divider {
  margin: 2% 0;
}
.posts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.post {
  position: relative;
  width: 24%;
  margin: 2%;
}
.post-image{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}
/* .overlay{
  position: relative;
} */
.overlay {
  /* Overlay background */
  z-index: 100;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 500ms;
  background: rgba(0, 0, 0, .5);
  border-radius: 12px;
  /* Text */
  color: #f5f5f5;
  /* Position */
  display: flex;
  flex-direction: column;
}

.overlay:hover {
  opacity: 1;
  transition: opacity 500ms;
}

.title {
  display: flex;
  justify-content: center;
  margin: 10px;
  padding-top: 10px;
}

.username {
  position: relative;
  padding: 20px;
}

.content {
  display: flex;
  margin: 10 20px;
  padding: 20px;
}
.interact{
  position: relative;
  max-width: 30px;
  max-height: 30px;
  left: 80%;
  bottom: 0;
}
.post-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form-field {
  width: 22%;
  border-radius: 12px;
  padding: 10px 0;
  margin: 10px 0;
}

input {
  padding: 6px 12px;
  width: 90%;
}
